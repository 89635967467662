import React from 'react';
import {Button, Result} from "antd";

class NotFount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBackHome = () => {
    this.props.history.push('/');
  } ;

  render() {
    return (
     <div>
       <Result
         status="404"
         title="404"
         subTitle="Sorry, the page you visited does not exist."
         extra={<Button type="primary" onClick={this.handleBackHome}>返回</Button>}
       />
     </div>
    )
  }
}

export default NotFount;
