import React, {Component} from 'react';
import './App.css';
import { Layout, Breadcrumb } from 'antd';
import MenuBar from "./components/Header/MenuBar";
import FooterBar from "./components/Footer/FooterBar";
import {Router, Route, Switch} from 'react-router-dom';
import { history } from '../App/_helpers/history';
import Home from "./components/Home/Home";
import NotFount from "./components/NotFound/NotFount";

const { Content} = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Layout className="layout">
          <MenuBar/>
          <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
              <Router history={history}>
                <Switch>
                  <Route exact path="/" component={Home}/>
                  <Route component={NotFount}/>
                </Switch>
              </Router>
            </div>
          </Content>
          <FooterBar/>
        </Layout>
      </div>
    )
  }
}

export default App;
