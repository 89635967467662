import React, {Component} from 'react';
import {Card} from "antd";
import './Home.css';
import ExtractForm from "./ExtractForm/ExtractForm";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
  };

  render() {
    return (
      <div id="home">
        <div className="site-card-border-less-wrapper">
          <Card
            title="证件照无损提取"
            bordered={false}
            headStyle={{fontSize: 30, textAlign: "center"}}
            style={{ height: 700}}>
            <ExtractForm/>
          </Card>
        </div>
      </div>
    )
  }
}

export default Home;
