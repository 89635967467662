import {handleResponse} from "../_helpers/handle-response";

export const extractPhotoService = {
  extractPhoto
};

export function extractPhoto(code) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json;charset=UTF-8',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    },
  };
  return fetch(`https://www.kuaipaikj.cn/web/orders/extra/${code}`, requestOptions).then(handleResponse);
}
