import React, {Component} from 'react';
import {Layout, Menu} from "antd";
import './MenuBar.css';

const { Header} = Layout;

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <div id="logo" className="logo">
            <p id="logo-text">证件照无损图提取系统</p>
          </div>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          </Menu>
        </Header>
      </div>
    )
  }
}

export default MenuBar;
