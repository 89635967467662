import React, {Component} from 'react';
import { Button, Card, Col, Descriptions, Form, Image, Input, Row} from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import {extractPhoto} from "../../../_services/extract.photo.service";

class ExtractForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      data: [],
    };
  }

  layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 8 },
  };

  handleFiledChange = (field, event) => {
    this.setState({
      [field]: event.target.value,
    });
  };

  handleSubmitExtract = () => {
    extractPhoto(this.state.code)
      .then(data => {
        console.log(data);
          this.setState({
            data: data,
          })
      })
      .catch(error => {
        alert(error);
      });
  };

  handleDownloadPhoto = () => {
    window.open(this.state.data.zip_url, '_blank');
  };

  render() {
    return (
      <div className="mt-3">
        <Form
          {...this.layout}
          name="basic"
          onFinish={this.handleSubmitExtract}
        >
          <Form.Item
            name="code"
            label="提取码"
            extra="小程序不提供照片存储功能，证件照自您支付成功起，7天后会自动删除，请尽早下载"
            rules={[
              {
                required: true,
                message: '请输入您的提取码！',
              },
            ]}
          >
            <Row gutter={8}>
              <Col span={19}>
                <Input
                  allowClear
                  value={this.state.code}
                  onChange={event => {this.handleFiledChange("code", event)}}/>
              </Col>
              <Col span={4}>
                <Button
                  className="ml-3"
                  type="basic"
                  htmlType="submit"
                >提取</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        {this.state.data.length !== 0 &&
        <Card
          style={{ width:400, margin: "auto" }}
          cover={
            <div>
              <Image
                alt="电子照"
                width={200}
                src={this.state.data.img_url}
              />
              {this.state.data.print_img_url &&
                <Image
                  alt="排版照"
                  width={200}
                  src={this.state.data.print_img_url}
                />
              }
            </div>
          }
          actions={[
              <Button icon={<CloudDownloadOutlined /> } onClick={this.handleDownloadPhoto}>
                下载
              </Button>
          ]}
        >
          <Descriptions title={this.state.data.name}>
            <Descriptions.Item label="证件照编号" span={3}>{this.state.data.order_id}</Descriptions.Item>
            <Descriptions.Item label="有效提取截止日期" span={3}>{this.state.data.expired_time}</Descriptions.Item>
          </Descriptions>
        </Card>
        }
      </div>
    )
  }
}

export default ExtractForm;
