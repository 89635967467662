import React, {Component} from 'react';
import { Layout} from 'antd';

const { Footer } = Layout;

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Footer style={{ textAlign: 'center' }}>AI pai extract ID photo ©2020 Created by AI pai tech</Footer>
      </div>
    )
  }
}

export default FooterBar;
